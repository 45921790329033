<template>
  <div class="portfolio-page-content">
    <div class="page-info">
      <Info
        :page-key="'import_portfolio'"
        :button-size="'lg'"
        :video-link="'https://player.vimeo.com/video/931012998?badge=0&autopause=0&player_id=0&app_id=58479&byline=0'"
      />
    </div>
    <Main class="general-background">
      <a-row justify="center">
        <a-col :xs="24" :md="24">
          <a-upload-dragger
            name="file"
            :multiple="false"
            accept=".csv"
            :customRequest="dummyRequest"
            :beforeUpload="beforeUpload"
            :fileList="fileList.slice(0)"
            :remove="onRemove"
          >
          <a-space direction="vertical" size="large" style="padding: 1rem 0;">
              <p class="ant-upload-text" style="color: #004851;">
                {{ $t("import_portfolio.drag_file") }}
              </p>
              <p class="ant-upload-hint">
                {{ $t("import_portfolio.only_csv") }}
              </p>
              <a-button class="rounded-button" style="background-color: inherit;">{{ $t("import_portfolio.browse_files") }}</a-button>
              <a-spin v-if="portfolioStore.loading" />
              <p class="ant-upload-drag-icon">
                <img src="img/icons/importarIconBig.svg" alt="" style="transform: scale(0.8);" />
              </p>
            </a-space>
          </a-upload-dragger>
        </a-col>
        <a-col :xs="24" :md="18" class="flex my-4 gap-4">
          <sdButton
            size="small"
            class="element-center rounded-button"
            type="secondary"
            @click="downloadStandard"
          >
            {{ $t("import_portfolio.download_standard") }}
          </sdButton>
        </a-col>
        <p class="color-danger" v-if="!error">{{ error }}</p>
      </a-row>
      <a-modal
        v-model:visible="showModal"
        :title="$t('generic.titles.import_portfolio')"
        :type="'primary'"
        :closable="false"
        @ok="appendFile"
        @cancel="handleCancel"
      >
      <template #footer>
          <a-button key="Cancel" @click="handleCancel">{{ $t("import_portfolio.modal_cancel") }}</a-button>
          <a-button
            key="Ok"
            type="primary"
            @click="appendFile"
            :disabled="
              (!portFolioOptions.new && !portFolioOptions.portfolio_to_update_id) ||
              (!portFolioOptions.new && portFolioOptions.update_date === null)"
          >
            {{ $t("import_portfolio.modal_ok") }}
          </a-button>
        </template>
        <label class="pt-3">{{
          $t("import_portfolio.is_new_portfolio")
        }}</label>
        <a-select
          v-model:value="portFolioOptions.new"
          style="width: 100%"
          :size="'small'"
        >
          <a-select-option
            :value="true"
            :title="$t('import_portfolio.new_true')"
          >
            {{ $t("import_portfolio.new_true") }}
          </a-select-option>
          <a-select-option
            :value="false"
            :title="$t('import_portfolio.new_false')"
          >
            {{ $t("import_portfolio.new_false") }}
          </a-select-option>
        </a-select>
        <template v-if="portFolioOptions.new">
          <!-- <label class="pt-3">{{
            $t("import_portfolio.portfolio_risks")
          }}</label>
          <a-select
            v-model:value="portFolioOptions.risk_level"
            style="width: 100%"
          >
            <a-select-option
              v-for="risk in risks"
              :value="risk"
              :key="risk"
            >
              {{ $t(`generic.risk_options.${risk}`, risk) }}
            </a-select-option>
          </a-select>
          <a-checkbox
            class="mt-4"
            v-model:checked="portFolioOptions.include_benchmark"
          > {{ $t("import_portfolio.include_benchmark") }}</a-checkbox> -->
        </template>
        <template v-else>
          <label class="pt-3">{{
            $t("import_portfolio.select_portfolio")
          }}</label>
          <a-select
            v-model:value="portFolioOptions.portfolio_to_update_id"
            style="width: 100%"
            ref="select"
            :showSearch="true"
            :filterOption="customFilter"
          >
            <a-select-option
              v-for="v in portfolioStore.listOfPortfolios"
              :value="v.portfolio_name"
              :key="v.portfolio_name"
              :title="v.portfolio_name"
            >
              {{ v.portfolio_name }}
            </a-select-option>
          </a-select>
        </template>
        <div class="mt-4">
          <label>{{
            $t("import_portfolio.portfolio_type")
          }}</label>
          <a-select
            v-model:value="portFolioOptions.portfolio_positions"
            style="width: 100%"
            :size="'small'"
          >
            <a-select-option
              :value="true"
              :title="$t('import_portfolio.type_statement')"
            >
              {{ $t("import_portfolio.type_statement") }}
            </a-select-option>
            <a-select-option
              :value="false"
              :title="$t('import_portfolio.type_transactions')"
            >
              {{ $t("import_portfolio.type_transactions") }}
            </a-select-option>
          </a-select>
        </div>
        <template v-if="portFolioOptions.portfolio_positions">
          <a-date-picker class="mt-4" v-model:value="portFolioOptions.update_date" :format="'YYYY/MM/DD'" />
          <label v-if="portFolioOptions.new" style="padding-left: 4px;" :for="portFolioOptions.update_date">
            {{ $t("import_portfolio.optional") }}
          </label>
        </template>
      </a-modal>
    </Main>
  </div>
</template>

<script setup>
import { Main } from "../styled";
import { ref, onMounted, reactive, watch} from "vue";
import { useRouter } from "vue-router";
// import { useAuthStore } from "@/stores/AuthStore";
import { usePortfolioStore } from "@/stores/PortfolioOverviewStore";
import { usePortfolioLimitStore } from "@/stores/PorfolioLimitsStore";
import Info from "@/components/infoModal/index.vue";

const router = useRouter();
// const authStore = useAuthStore();
const portfolioStore = usePortfolioStore();
const portfolioLimitStore = usePortfolioLimitStore();

const showModal = ref(false);
const fileList = ref([]);
const optionList = ref([]);
const portFolioOptions = reactive({
  new: true,
  portfolio_positions: true,
  portfolio_to_update_id: null,
  update_date: null,
  risk_level: null,
  include_benchmark: false,
  file: null
});
const error = ref("");

// const risks = computed(() => Object.keys(authStore?.config["risk_levels"]));
const importPortfolio = async () => {
  const today = new Date();
  today.setDate(today.getDate() + 1);
  try {
    error.value = "";
    const formData = new FormData();
    fileList.value.forEach((file, index) => {
      formData.append("update[]", optionList.value[index].new ? 0 : 1);
      formData.append("portfolio_positions[]", optionList.value[index].portfolio_positions);
      formData.append("portfolio_to_update_id[]", optionList.value[index].portfolio_to_update_id);
      formData.append("update_date[]", optionList.value[index].update_date !== null ? new Date(optionList.value[index].update_date).toISOString().slice(0, 10) : today.toISOString().slice(0, 10));
      formData.append("risk_level[]", null);
      formData.append("include_benchmark[]", null);
      formData.append("fileList[]", file);
    });
    portfolioStore.portfolio_type = 2;
    portfolioStore.original_portfolio_name = fileList.value[0].name.substring(0, fileList.value[0].name.lastIndexOf("."));
    portfolioLimitStore.selected_mandate = null;
    await portfolioStore.importPortfolio(formData);
    await portfolioStore.getUserPortfolios();
    const ids = portfolioStore.selectedPortfolioIds.join(",");
    router.push({ name: "portfolio-overview", params: { ids: ids } });
  } catch (err) {
    console.log(err);
    error.value = err?.response?.data?.message || err;
  }
};

const downloadStandard = async () => {
  try {
    error.value = "";
    await portfolioStore.downloadStandard();
  } catch (err) {
    console.log(err);
    error.value = err?.response?.data?.message || err;
  }
};

const dummyRequest = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};
const customFilter = (input, option) => {
  return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const beforeUpload = (file) => {
  //const daysBefore = authStore?.config["default_before_days"] || 0;
  showModal.value = true;
  portFolioOptions.file = file;
  portFolioOptions.new = true;
  portFolioOptions.portfolio_positions = true;
  portFolioOptions.portfolio_to_update_id = null;
  //const today = new Date();
  //today.setDate(today.getDate() - daysBefore);
  portFolioOptions.update_date = null;//today.toDateString();
  portFolioOptions.risk_level = null;
  portFolioOptions.include_benchmark = false;
  return false;
};
const handleNewChange = () => {
  if (!portFolioOptions.new) {
    const today = new Date();
    today.setDate(today.getDate() - 10);
    portFolioOptions.update_date = today.toISOString().slice(0, 10);
  } else {
    portFolioOptions.update_date = null;
  }
};

watch(() => portFolioOptions.new, handleNewChange, { immediate: true });

const handleCancel = () => {
  showModal.value = false;
  return false;
};

const appendFile = async () => {
  fileList.value = [...fileList.value, portFolioOptions.file];
  optionList.value = [
    ...optionList.value,
    {
      ...portFolioOptions
    },
  ];
  showModal.value = false;
  await importPortfolio();
};

const onRemove = (fileToDelete) => {
  const index = fileList.value.findIndex(
    (file) => file.uid === fileToDelete.uid
  );
  if (index > -1) {
    fileList.value.splice(index, 1);
    optionList.value.splice(index, 1);
  }
};

onMounted(async () => {
  await portfolioStore.getUserPortfolios();
});
</script>
<style scoped lang="scss">
.general-background {
  background-color: #F2F2F2;
}

.rounded-button {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border-radius: 24px !important;
}

.portfolio-page-content {
  padding: 0 5rem;
  position: relative;
  .page-info {
    position: absolute;
    left: 3.5rem;
    top: -1rem;
    z-index: 100;
  }
}

@media only screen and (max-width: 768px) {
  .portfolio-page-content {
    padding: 0 1rem;
    .page-info {
      left: 0.5rem;
      top: -1.5rem;
    }
  }
}

:deep(.ant-upload-drag) {
  height: auto;
  border-radius: 0 !important;
  border-style: solid;
  border-color: #004851;
  background-color: #F2F2F2 !important;
}

:deep(.ant-page-header) {
  padding-bottom: 4px;
  padding-left: 0px;
  font-size: 16px;
}

:deep(.ant-upload-list) {
  &.ant-upload-list-text {
    & > * {
      padding: 0px;
      width: 30%;
      margin: 0px;
      @media only screen and (max-width: 1149px) {
        margin: 0 auto;
        margin-left: -10px;
        width: 70vw;
      }

      .ant-upload-list-item-card-actions {
        height: 100%;
        a {
          span {
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              width: 16px !important;
              height: 16px !important;
            }
          }
        }
      }
    }
  }
}
</style>
